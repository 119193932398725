export type Config = {
  isDev: boolean;

  apiSecure: boolean;
  apiHost: string;

  pusherKey: string;
  pusherCluster: string;

  cloverClientId: string;
  cloverServerUrl: string;

  googleAnalyticsId: string;

  googleMapsApiKey: string;

  getBaseURL: () => string;
  getBaseWsURL: () => string;
};

let config: Config;

export const getConfig = async (): Promise<Config> => {
  if (config) {
    return config;
  }

  const response = await fetch("/config.json");
  const data = await response.json();

  const apiSecure = data.apiSecure === "true";

  return {
    isDev: process.env.NODE_ENV === "development",

    apiSecure: apiSecure,
    apiHost: data.apiHost,

    pusherKey: data.pusherKey,
    pusherCluster: data.pusherCluster,

    cloverClientId: data.cloverClientId,
    cloverServerUrl: data.cloverServerUrl,

    googleAnalyticsId: data.googleAnalyticsId,

    googleMapsApiKey: data.googleMapsApiKey,

    getBaseURL: () => `http${apiSecure ? "s" : ""}://${data.apiHost}`,
    getBaseWsURL: () => `ws${apiSecure ? "s" : ""}://${data.apiHost}`,
  };
};
