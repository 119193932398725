import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import { authService } from "./auth";
import { Config, getConfig } from "./config";

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

async function registerApplications(config: Config) {
  for (const app of applications) {
    await System.import(app.name);
    registerApplication({
      name: app.name,
      app: app.app,
      activeWhen: app.activeWhen,
      customProps: {
        authService,
        config,
      },
    });
  }
}

async function initialize() {
  const config = await getConfig();
  await authService.init(config);
  await registerApplications(config);
  layoutEngine.activate();
  start();
}

initialize();
