import SDK from "casdoor-js-sdk";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Config } from "./config";

const fpPromise = FingerprintJS.load();

const signinRedirectPath = "/auth/signin-callback";

const publicRoutes = [
  "signature_capture",
  "gb_form",
  "paymentportal",
  "newclient",
  "auth/suspended",
];

const casdoor = new SDK({
  serverUrl: "https://casdoor.staging.glassbiller.com",
  clientId: "d7a4da43409e75f3ec93",
  organizationName: "gb",
  appName: "gb",
  redirectPath: signinRedirectPath,
  storage: window.localStorage,
});

// Auth info
let auth = null;

// Identity info
let identity = null;

// Visitor fingerprint id used to uniquely identify the user session
let visitorFingerprintId = null;

// Broadcast channel to communicate with other tabs
const authChannel = new BroadcastChannel("auth");
const events = {
  tokenRefreshed: "token-refreshed",
  fingerprintSuspended: "fingerprint-suspended",
  loggedOut: "logged-out",
  tabClosed: "tab-closed",
};

// Local storage key to prevent multiple refresh token requests at the same time
const refreshingTokenStorageKey = "refreshing-token";

// Flag to prevent multiple refresh token requests at the same time
let refreshingToken = false;

// Timeout id for the token refresh
let refreshTokenTimeoutId;

const getAuth = () => auth;

const getIdentity = () => identity;

const getVisitorFingerprintId = () => visitorFingerprintId;

const getAccessToken = () => {
  return auth?.access_token || "";
};

const getAuthorizationHeader = () => {
  if (!auth) return "";
  return `${auth.token_type} ${auth.access_token}`;
};

const isPublicRoute = () => {
  return !!publicRoutes.find(
    (i) => window.location.pathname.toLowerCase().indexOf(i) >= 0
  );
};

// Listen for messages from the auth channel
authChannel.onmessage = (event) => {
  if (event.data.type === events.tokenRefreshed) {
    console.log("Token refreshed by another tab");
    restoreAuthFromStorage();
  }

  if (event.data.type === events.fingerprintSuspended) {
    console.log("Fingerprint suspended on another tab");
    handleSuspendedFingerprint();
  }

  if (event.data.type === events.loggedOut) {
    console.log("Logged out on another tab");
    logout();
  }

  if (event.data.type === events.tabClosed) {
    console.log("Tab closed");
    restoreAuthFromStorage();
  }
};

window.addEventListener("beforeunload", (event) => {
  if (refreshingToken) {
    console.log("Tab closed while refreshing token");
    localStorage.removeItem(refreshingTokenStorageKey);
    authChannel.postMessage({ type: events.tabClosed });
    authChannel.close();
  }
});

// Initialize the auth service
const init = async (config: Config) => {
  if (isPublicRoute()) {
    return;
  }

  // Generate unique visitor id with FingerprintJS
  const fp = await fpPromise;
  const result = await fp.get();
  visitorFingerprintId = result.visitorId;

  // This is to handle the case where the user is redirected back to the app after signing in.
  // Exchange the code for the access token if the current path is the redirect path.
  // Then, update the visitor fingerprint id on the server.
  if (window.location.pathname === signinRedirectPath) {
    try {
      const result = await casdoor.exchangeForAccessToken();
      await setNewAuth(result);
      await fetch(`${config.getBaseURL()}/api/update-visitor-fingerprint`, {
        method: "POST",
        headers: {
          Authorization: getAuthorizationHeader(),
          "Visitor-Fingerprint-Id": visitorFingerprintId,
        },
      });
      window.location.href = "/";
    } catch (error) {
      console.error("Failed to exchange code for an access token", error);
      await logout();
    }
  }

  await restoreAuthFromStorage();
};

// Redirect to the sign-in page
const signInRedirect = async () => {
  await casdoor.signin_redirect();
};

// Restore auth info from local storage
const restoreAuthFromStorage = async () => {
  const storageAuth = localStorage.getItem("auth");
  auth = storageAuth ? JSON.parse(storageAuth) : null;

  const storageIdentity = localStorage.getItem("identity");
  identity = storageIdentity ? JSON.parse(storageIdentity) : null;

  await scheduleTokenRefresh();
};

// Set the new auth info and update the identity info.
// Schedule token refresh after setting the new auth info.
const setNewAuth = async (val) => {
  localStorage.removeItem(refreshingTokenStorageKey);
  refreshingToken = false;

  localStorage.setItem("auth", JSON.stringify(val));
  auth = val;

  const { payload } = casdoor.parseAccessToken(val.access_token);
  localStorage.setItem("identity", JSON.stringify(payload));
  identity = payload;

  await scheduleTokenRefresh();
};

// Schedule token refresh based on the expiration time of the access token.
// Clear the previous timeout if it exists.
// If the access token is expired, refresh it using the refresh token.
const scheduleTokenRefresh = async () => {
  console.log("Scheduling token refresh...");

  if (refreshTokenTimeoutId) {
    console.log("Clearing previous token refresh timeout");
    clearTimeout(refreshTokenTimeoutId);
  }

  const expiresAt = identity?.exp;

  if (!expiresAt) {
    await logout();
    return;
  }

  const refreshTime = (expiresAt - Date.now() / 1000 - 60) * 1000;

  if (refreshTime <= 0) {
    console.log("Access token expired, refreshing token...");
    await refreshAccessToken();
    return;
  }

  refreshTokenTimeoutId = setTimeout(async () => {
    await refreshAccessToken();
  }, refreshTime);
};

// Refresh the access token using the refresh token and update the auth info.
// If the refresh token is invalid, log out the user.
// Retry 10 times with a 3-second interval if the refresh token is invalid.
// If the refresh token is still invalid after 10 retries, log out the user.
// Use a local storage key to prevent multiple refresh token requests at the same time.
// Broadcast a token refreshed event after the token is refreshed.
const refreshAccessToken = async () => {
  console.log("Refreshing access token...");

  const refreshToken = auth?.refresh_token;

  if (!refreshToken) {
    await logout();
    return;
  }

  if (localStorage.getItem(refreshingTokenStorageKey)) {
    console.log("Another tab is refreshing the token, waiting...");
    return;
  }

  localStorage.setItem(refreshingTokenStorageKey, "true");
  refreshingToken = true;

  let retryCount = 0;
  while (retryCount < 10) {
    try {
      const result = await casdoor.refreshAccessToken(refreshToken);
      await setNewAuth(result);
      break;
    } catch (error) {
      console.warn("Failed to refresh access token, retrying in 3 seconds...");
      retryCount++;
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  }

  localStorage.removeItem(refreshingTokenStorageKey);
  refreshingToken = false;

  authChannel.postMessage({ type: events.tokenRefreshed });
};

// Log out the user by removing the auth info from local storage.
const logout = async () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("identity");
  localStorage.removeItem(refreshingTokenStorageKey);
  auth = null;
  identity = null;
  refreshingToken = false;
  casdoor.clearState();

  authChannel.postMessage({ type: events.loggedOut });

  await signInRedirect();
};

// Handle the case where the user's fingerprint is suspended.
// Log out the user and redirect to the suspended page.
const handleSuspendedFingerprint = async () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("identity");
  localStorage.removeItem(refreshingTokenStorageKey);
  auth = null;
  identity = null;
  refreshingToken = false;
  casdoor.clearState();

  authChannel.postMessage({ type: events.fingerprintSuspended });

  window.location.href = "/auth/suspended";
};

export const authService = {
  getAuth,
  getIdentity,
  getVisitorFingerprintId,
  getAccessToken,
  getAuthorizationHeader,
  isPublicRoute,
  init,
  signInRedirect,
  logout,
  handleSuspendedFingerprint,
};
